import type {Variants} from 'framer-motion';

export const ModalEnterExitAnimation: Variants = {
    enter: {
        display: 'grid',
        opacity: 1,
        y: '0px'
    },
    exit: {
        opacity: 0,
        transitionEnd: {display: 'none'},
        y: '80px'
    },
    initial: {
        opacity: 0,
        y: '80px'
    }
};