/* eslint-disable no-param-reassign */
import type {Dispatch, RefObject, SetStateAction} from 'react';

import {lighten, media, spacing} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled, {keyframes} from 'styled-components';

import {CustomModalScrollContainer} from '../CustomModalScrollContainer';
import {useModalAnimation} from 'UI/components/layout/shared/Modal/useModalAnimation';

import {ModalEnterExitAnimation} from 'UI/animations/modals';

import {X} from 'Images/icons/ultra';

import type {WithChildren} from 'types/global';

/**
 * Factory function for creating a modal component.
 *
 * This function creates a modal component that can be used to display content
 * in a modal dialog. The modal component supports animation and can be
 * controlled using the `openModal` and `closeModal` functions returned by
 * this factory.
 *
 * @param state Ref object for managing the modal state.
 * @returns The modal component.
 *
 * @example
 * ```tsx
 * const state = useRef<Dispatch<SetStateAction<boolean>>>(null);
 * const Modal = modalFactory(state);
 * ```
 */
const modalFactory = (state: RefObject<Dispatch<SetStateAction<boolean>>>) => {
    interface ComponentProps {
        /** The test id. */
        testId: string;
    }

    /**
     * Modal component.
     *
     * This component represents a modal dialog that can be used to display
     * content in a modal overlay. It manages the modal state and supports
     * animation when showing or hiding the modal.
     *
     * @param props          Component props.
     * @param props.size     The size of the component.
     * @param props.testId   The test id.
     * @param props.children The children to be rendered inside the modal.
     * @returns The modal component.
     */
    const Modal = ({children, testId}: WithChildren<ComponentProps>) => {
        const {controls, modal, setOpen, showContent} = useModalAnimation(state);

        return (
            <Wrapper
                ref={modal}
                animate={controls}
                data-cy={testId}
                initial="initial"
                variants={ModalEnterExitAnimation}
            >
                <CloseWrapper onClick={() => setOpen(false)}>
                    <X height={28} width={28} />
                </CloseWrapper>
                {showContent && (
                    <CustomModalScrollContainer>
                        {children}
                    </CustomModalScrollContainer>
                )}
            </Wrapper>
        );
    };

    Modal.displayName = 'Modal';
    Modal.defaultProps = {
        size: 'default',
        testId: undefined
    };

    return Modal;
};

export {modalFactory};

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const Wrapper = styled(motion.dialog)`
    align-content: stretch;
    background: ${({theme}) => theme.colors.ultraModalBg};
    border: none;
    border-radius: 16px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: calc(100% - ${spacing(8)});
    max-height: calc(100% - ${spacing(8)});
    max-width: calc(100% - ${spacing(8)});
    min-height: 20rem;
    opacity: 0;
    outline: none;
    overflow: hidden;
    padding: ${spacing(10)} 0 0 0;
    transform: translateY(80px);
    width: 100%;

    &::backdrop {
        animation: ${fadeIn} 0.3s ease-in-out backwards;
        animation-direction: reverse;
        background-color: rgba(0, 0, 0, 0.7);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }

    &[open]::backdrop {
        animation: ${fadeIn} 0.3s ease-in-out forwards;
    }

    ${media('sm')} {
        max-height: calc(100% - ${spacing(10)});
        max-width: calc(100% - ${spacing(15)});
    }

    ${media('md')} {
        max-height: calc(100% - ${spacing(15)});
        max-width: calc(100% - ${spacing(30)});
        padding: ${spacing(20)} 0 0 0;
    }

    ${media('xl')} {
        aspect-ratio: 1634/985;
        max-width: calc(100% - ${spacing(60)});
    }

    ${media('xxl')} {
        aspect-ratio: 1634 / 806;
        max-width: calc(100% - ${spacing(60)});
    }
`;

const CloseWrapper = styled.div`
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 3.2rem;
    justify-content: center;
    position: absolute;
    right: ${spacing(4)};
    top: ${spacing(2)};
    transition: background-color 0.3s 0s ease-in-out;
    width: 3.2rem;
    z-index: 10;

    &:hover {
        background: ${({theme}) => lighten(theme.colors.ultraModalBg, 10)};
        cursor: pointer;
    }

    ${media('md')} {
        right: ${spacing(5)};
        top: ${spacing(5)};
    }
`;